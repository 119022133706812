// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chi-sono-js": () => import("./../../../src/pages/chi-sono.js" /* webpackChunkName: "component---src-pages-chi-sono-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-corsi-corso-base-teoria-js": () => import("./../../../src/pages/corsi/corso-base-teoria.js" /* webpackChunkName: "component---src-pages-corsi-corso-base-teoria-js" */),
  "component---src-pages-corsi-index-js": () => import("./../../../src/pages/corsi/index.js" /* webpackChunkName: "component---src-pages-corsi-index-js" */),
  "component---src-pages-cosa-faccio-illustrazioni-personalizzate-js": () => import("./../../../src/pages/cosa-faccio/illustrazioni-personalizzate.js" /* webpackChunkName: "component---src-pages-cosa-faccio-illustrazioni-personalizzate-js" */),
  "component---src-pages-cosa-faccio-index-js": () => import("./../../../src/pages/cosa-faccio/index.js" /* webpackChunkName: "component---src-pages-cosa-faccio-index-js" */),
  "component---src-pages-cosa-faccio-ricette-disegnate-js": () => import("./../../../src/pages/cosa-faccio/ricette-disegnate.js" /* webpackChunkName: "component---src-pages-cosa-faccio-ricette-disegnate-js" */),
  "component---src-pages-cosa-faccio-valorizzare-i-tuoi-prodotti-js": () => import("./../../../src/pages/cosa-faccio/valorizzare-i-tuoi-prodotti.js" /* webpackChunkName: "component---src-pages-cosa-faccio-valorizzare-i-tuoi-prodotti-js" */),
  "component---src-pages-i-miei-lavori-clienti-js": () => import("./../../../src/pages/i-miei-lavori/clienti.js" /* webpackChunkName: "component---src-pages-i-miei-lavori-clienti-js" */),
  "component---src-pages-i-miei-lavori-index-js": () => import("./../../../src/pages/i-miei-lavori/index.js" /* webpackChunkName: "component---src-pages-i-miei-lavori-index-js" */),
  "component---src-pages-i-miei-lavori-portfolio-js": () => import("./../../../src/pages/i-miei-lavori/portfolio.js" /* webpackChunkName: "component---src-pages-i-miei-lavori-portfolio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-corso-js": () => import("./../../../src/templates/corso.js" /* webpackChunkName: "component---src-templates-corso-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-lavori-clienti-js": () => import("./../../../src/templates/lavori-clienti.js" /* webpackChunkName: "component---src-templates-lavori-clienti-js" */),
  "component---src-templates-lavori-js": () => import("./../../../src/templates/lavori.js" /* webpackChunkName: "component---src-templates-lavori-js" */),
  "component---src-templates-lezione-js": () => import("./../../../src/templates/lezione.js" /* webpackChunkName: "component---src-templates-lezione-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-prodotti-js": () => import("./../../../src/templates/prodotti.js" /* webpackChunkName: "component---src-templates-prodotti-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */)
}

