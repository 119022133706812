/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.shouldUpdateScroll = args => {
    return true;
  };

const injectIubendaScript = () => {
  const iubendaScriptId = 'iubenda-script-in-gatsby';
  // Remove existing iubenda script and reinsert it to trigger re-execution
  const existingScript = document.getElementById(iubendaScriptId);
  if (existingScript) {
      existingScript.parentNode.removeChild(existingScript);
  }
  const s = document.createElement(`script`);
  s.id = iubendaScriptId;
  s.type = `text/javascript`
  s.src="https://cdn.iubenda.com/iubenda.js";
  document.getElementsByTagName(`head`)[0].appendChild(s);
}

exports.onRouteUpdate = () => {
  // Check wheter the document has loaded or not
  if (document.readyState === 'complete'){
      // console.log('Immediately insert iubenda script');
      injectIubendaScript();
  }else{
      // console.log('Insert iubenda script on load event');
      if (window.addEventListener) {
          window.addEventListener("load", injectIubendaScript, false);
      } else if (window.attachEvent){
          window.attachEvent("onload", injectIubendaScript);
      } else {
          window.onload = injectIubendaScript;
      }
  }
}